import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { memo } from "react";
import BrandDark from "highcharts/themes/brand-dark";

BrandDark(Highcharts);

// tradução manual para pt-BR
Highcharts.setOptions({
  lang: {
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ],
    weekdays: [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ],
    shortMonths: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    rangeSelectorZoom: "Intervalo",
    rangeSelectorFrom: "De",
    rangeSelectorTo: "Até",
    loading: "Carregando...",
    printChart: "Imprimir Gráfico",
    downloadPNG: "Baixar imagem PNG",
    downloadJPEG: "Baixar imagem JPEG",
    downloadPDF: "Baixar documento PDF",
    downloadSVG: "Baixar imagem SVG",
    downloadCSV: "Baixar dados CSV",
    downloadXLS: "Baixar documento XLS",
    viewData: "Ver tabela de dados",
  },
  accessibility: { enabled: true },
});

export const StockChart = memo(
  ({
    data,
    options,
    className,
    type = "line",
    constructorType = "stockChart",
    theme = "blue",
    ...props
  }) => {
    const transformDataForHighstock = (data) => {
      return data.map((item) => {
        const timestamp = new Date(item.data).getTime();
        return {
          x: Number.isNaN(timestamp) ? null : timestamp,
          y: item.valor,
          name: item.nome,
        };
      });
    };

    const transformedData = transformDataForHighstock(data);

    // Agrupa os dados por nome para criar múltiplas séries
    const seriesData = transformedData.reduce((acc, cur) => {
      if (!acc[cur?.name ?? ""]) {
        acc[cur?.name ?? ""] = [];
      }
      acc[cur?.name ?? ""].push([cur.x, cur.y]);
      return acc;
    }, {});

    // Cria as séries no formato esperado pelo Highstock
    const series = Object.keys(seriesData).map((name) => {
      return {
        name,
        data: seriesData[name],
        type: type,
      };
    });

    const colorIndex = theme === "blue" ? 0 : 1;

    const colors = [
      { line: "#4c9bcb", area: "#60c3fe" },
      { line: "#17AD00", area: "#1CCF00" },
    ];

    const plotOptions = {
      area: {
        lineColor: colors[colorIndex].line,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, colors[colorIndex].area],
            [1, `${colors[colorIndex].area}00`],
          ],
        },
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        opacity: 0.9,
      },
    };

    return (
      <div>
        {data.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={constructorType}
            options={{ plotOptions, ...options(series) }}
            {...props}
          />
        ) : (
          <div>Carregando dados...</div>
        )}
      </div>
    );
  }
);
