import React, { useEffect, useState } from "react";
import { request } from "../../../functions/request";
import {
  Button,
  Checkbox,
  DatePicker,
  Descriptions,
  Form,
  Input,
  Select,
} from "antd";
import { updateData } from "../../../functions/functionsUtility";
import { FaSave } from "react-icons/fa";
import dayjs from "dayjs";

const cafOptions = [
  { value: "caf_minimo", label: "Mínima:", span: 2 },
  { value: "caf_maximo", label: "Máxima:", span: 2 },
  { value: "caf_icms", label: "ICMS:", span: 2, type: "checkbox" },
  { value: "caf_funrural", label: "FUNRURAL:", span: 2, type: "checkbox" },
];

const categorias = [
  //   { value: "0", label: "Cerrado" },
  { value: "1", label: "Sul de Minas" },
  { value: "2", label: "Mogiana" },
  { value: "3", label: "Con 7-8" },
  { value: "4", label: "Riado" },
  { value: "5", label: "Bebida Rio" },
  { value: "6", label: "Pilha Batida Dura" },
  { value: "7", label: "Pilha Batida Riadas" },
  //   { value: "8", label: "600/700 Def" },
];

export default function IntergraosCafeBica() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uf, setUf] = useState(null);
  const [date, setDate] = useState(dayjs().format("DD/MM/YYYY"));

  const onChange = (_, dateString) => {
    setDate(dateString);
  };

  async function getData(values) {
    setIsLoading(true);
    const currentPathWithQuery = `/cafe_bica/getCafBica?categoria=${
      values.uf
    }&data=${encodeURIComponent(values.date)}`;
    const response = await request(currentPathWithQuery, {
      method: "GET",
    }).catch((reason) => {
      console.log(reason);
      setCotacoesData("");
    });
    const data = {
      ...response[0],
      endpoint: "updateCafBica",
      path: "cafe_bica",
    };
    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number" && el !== "caf_categoria") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      }
    });
    setCotacoesData(data);
    setIsLoading(false);
    console.log(data);
  }

  useEffect(() => {
    if (uf && date) getData({ uf, date });
  }, [uf, date]);

  console.log(cotacoesData);

  return (
    <>
      {/* <DescriptionsLayout
        title={"Intergrãos - Café - Físico - Bica - Alterando Mercado Futuro"}
        isLoading={isLoading}
        items={[...items, ...aux.filter(Boolean), ...auxCheck.filter(Boolean)]}
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={<FaSave />}
            disabled={isLoading}
            onClick={() => {
              console.log(cotacoesData);
              updateData(cotacoesData);
            }}
          >
            Atualizar
          </Button>
        }
      /> */}
      <Form>
        <Descriptions
          title={"Intergrãos - Café - Físico - Bica - Alterando Mercado Futuro"}
          bordered
          extra={
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              icon={<FaSave />}
              disabled={isLoading}
              onClick={() => {
                console.log(cotacoesData);
                updateData(cotacoesData);
              }}
            >
              Atualizar
            </Button>
          }
          style={{ padding: 10 }}
        >
          <Descriptions.Item
            label={"Categoria"}
            span={2}
            children={
              <Select
                style={{ width: "100%", marginTop: "10px" }}
                onChange={(value) => {
                  setCotacoesData({ ...cotacoesData, caf_categoria: value });
                  setUf(value);
                }}
                placeholder="Selecione o estado"
              >
                {categorias.map((el, index) => {
                  return (
                    <Select.Option key={index} value={el.value}>
                      {el.label}
                    </Select.Option>
                  );
                })}
              </Select>
            }
          />
          <Descriptions.Item
            label={"Data"}
            span={1}
            children={
              <DatePicker
                defaultValue={dayjs()}
                format={"DD/MM/YYYY"}
                onChange={onChange}
              />
            }
          />
          <Descriptions.Item
            label={"Última Alteração"}
            span={3}
            children={dayjs(
              cotacoesData?.caf_data,
              "YYYY-MM-DDTHH:mm:ss.zzz"
            ).format("DD/MM/YYYY [às] HH:mm")}
          />
          {!isLoading &&
            cafOptions.map((element) => (
              <Descriptions.Item
                label={element.label}
                span={element.span}
                children={
                  element?.type === "checkbox" ? (
                    <Checkbox
                      checked={cotacoesData[element.value]}
                      onChange={(event) => {
                        const inputValue = event.target.checked;
                        const formattedValue = inputValue;
                        const newData = {
                          ...cotacoesData,
                          [element.value]: formattedValue,
                        };
                        setCotacoesData(newData);
                      }}
                    />
                  ) : (
                    <Input
                      datatype=""
                      value={cotacoesData[element.value]}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue.trim();
                        const newData = {
                          ...cotacoesData,
                          [element.value]: formattedValue,
                        };
                        setCotacoesData(newData);
                      }}
                    />
                  )
                }
              />
            ))}
        </Descriptions>
      </Form>
    </>
  );
}
