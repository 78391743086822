import moment from "moment";
import { formatToBRL } from "../../../functions/utils";

export default function TabelaCentralGraficos({ table, className }) {
  return (
    <div
      style={{
        backgroundColor: "#1c1c1c",
        padding: "16px",
        borderRadius: "8px",
        color: "#f4f4f5",
        marginTop: "16px",
        width: "100%",
      }}
      className={className}
    >
      <table
        style={{
          width: "100%",
          color: "#f4f4f5",
          borderSpacing: "0 12px",
          textAlign: "left",
        }}
      >
        <caption style={{ marginBottom: "8px", fontWeight: "bold" }}>
          Período Analisado:{" "}
          <strong>
            {table &&
              moment(table[0].dataIni, "MM-DD-YYYY").format("DD/MM/YYYY")}
          </strong>{" "}
          até{" "}
          <strong>
            {table &&
              moment(table[0].dataFim, "MM-DD-YYYY").format("DD/MM/YYYY")}
          </strong>
        </caption>
        <thead>
          <tr>
            <th>Mercado</th>
            <th>UF</th>
            <th>Média</th>
            <th>Valor Máx.</th>
            <th>Valor Mín.</th>
            <th>Variação</th>
            <th>Valor Inicial</th>
            <th>Valor Final</th>
          </tr>
        </thead>
        <tbody>
          {table?.map((row, index) => (
            <tr key={index} style={{ height: "40px", textAlign: "left" }}>
              <td style={{ fontWeight: "bold" }}>{row.nome}</td>
              <td style={{ fontWeight: "bold" }}>{row.uf}</td>
              <td>{formatToBRL(row.media)}</td>
              <td style={{ fontWeight: "bold" }}>{formatToBRL(row.maximo)}</td>
              <td style={{ fontWeight: "bold" }}>{formatToBRL(row.minimo)}</td>
              <td
                style={{
                  fontWeight: "500",
                  color:
                    Number(row.variacao) > 0
                      ? "#22c55e"
                      : Number(row.variacao) < 0
                      ? "#ef4444"
                      : "#f59e0b",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row.variacao &&
                  (Number(row.variacao) > 0 ? (
                    <span style={{ marginRight: "4px", fontSize: "16px" }}>
                      ▲
                    </span>
                  ) : Number(row.variacao) < 0 ? (
                    <span style={{ marginRight: "4px", fontSize: "16px" }}>
                      ▼
                    </span>
                  ) : (
                    <span style={{ marginRight: "4px", fontSize: "16px" }}>
                      ➔
                    </span>
                  ))}
                {row.variacao && Number(row.variacao).toFixed(2)}%
              </td>
              <td style={{ fontWeight: "bold" }}>
                {formatToBRL(row.valorInicial)}
              </td>
              <td style={{ fontWeight: "bold" }}>
                {formatToBRL(row.valorFinal)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
