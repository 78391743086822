import React, { createContext, useEffect, useState } from "react";
import { request } from "../functions/request";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/actions/userActions";
import Aguarde from "../components/aguarde/aguarde";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const useHaveAccess = useSelector((state) => state.user.user);
  const [isLogged, setIsLogged] = useState(null); // Inicializa como null para indicar que ainda está verificando
  const [accessLevel, setAccessLevel] = useState(null);
  const logout = () => {
    if (window.confirm("Deseja mesmo sair?").valueOf()) {
      Cookies.remove("x-access-token");
      dispatch(setUser({ username: "" }));
      setIsLogged(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    request("/login/islogged", {
      method: "GET",
    })
      .then((response) => {
        if (response.auth) {
          dispatch(
            setUser({ username: response.id, haveAcess: response.haveAcess }),
            setAccessLevel({usr_correcao: response.usr_correcao, usr_analise: response.usr_analise, usr_video: response.usr_video, usr_noticias: response.usr_noticias, usr_editor: response.usr_editor})
          );
          setIsLogged(true);
        } else {
          setIsLogged(false);
        }
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
        setIsLogged(false);
      });
  }, []);

  if (isLogged === null) {
    return <Aguarde />;
  }

  return (
    <AuthContext.Provider value={{ isLogged, useHaveAccess, logout, accessLevel }}>
      {children}
    </AuthContext.Provider>
  );
}
