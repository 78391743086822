import React, { useCallback, useEffect, useState } from "react";
import { graphicCenterOptions } from "../../components/stockchart/options";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  message,
  Select,
  Space,
} from "antd";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import api from "../../http/api";
import locale from "antd/es/locale/pt_BR";
import { request } from "../../functions/request";
import { mapLabelToName } from "../Correcao/Correcao";
import { AiOutlineSearch } from "react-icons/ai";
import TabelaCentralGraficos from "./components/TabelaCentralGraficos";
import { StockChart } from "../../components/stockchart/StockChart";
import { FaMinusCircle, FaRegClipboard } from "react-icons/fa";
import { handleCopyText } from "../../functions/utils";
import TextArea from "antd/es/input/TextArea";
const { RangePicker } = DatePicker;

export default function CentralGraficos() {
  const [iframeLink, setIframeLink] = useState(null);
  const [URLSearchParams] = useSearchParams();
  const id = URLSearchParams.get("id");
  const uf = URLSearchParams.get("uf");
  const [date, setDate] = useState(null);
  const [search, setSearch] = useState([
    {
      id: id ? id : "21",
      uf: uf ? uf : "",
      order: "",
      isLoading: false,
      ufList: [{ id: "", uf: "" }],
    },
  ]);
  const [data, setData] = useState(null);
  const [table, setTable] = useState(null);
  const [optCommo, setOptCommo] = useState();
  const [commList, setCommList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  // req de todas as cotações
  useEffect(() => {
    request("/correcoes/getAllCommodityGraf/", {
      method: "GET",
    })
      .then((response) => {
        // console.log(response);
        const optionsCorrecoes = response.reduce((acc, item) => {
          const mappedLabel = mapLabelToName(item.tipo);

          const existingLabel = acc.find(
            (group) => group.label === mappedLabel
          );

          if (existingLabel) {
            existingLabel.options.push({
              value: item.id,
              label: item.nome,
            });
          } else {
            acc.push({
              label: mappedLabel,
              options: [{ value: item.id, label: item.nome }],
            });
          }

          return acc;
        }, []);
        setCommList(response);
        setOptCommo(optionsCorrecoes);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });
  }, []);

  async function getUfByOrder(value, index) {
    const commodity = commList.find((item) => item.id === value);
    const { ordem } = commodity;
    if (ordem) {
      setSearch((prevState) => {
        const updatedState = prevState.map((item, searchIndex) => {
          if (item.order === ordem.toString() && index === searchIndex) {
            return {
              ...item,
              isLoading: true,
            };
          }
          return item;
        });
        return updatedState;
      });

      await request(`/correcoes/getUfByOrdem/${ordem}`, {
        method: "GET",
      })
        .then((response) => {
          setSearch((prevState) => {
            const updatedState = prevState.map((item, searchIndex) => {
              if (item.order === ordem.toString() && index === searchIndex) {
                return {
                  ...item,
                  isLoading: false,
                  ufList: response,
                };
              }
              return item;
            });
            return updatedState;
          });
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
          message.error("Ocorreu um erro inesperado! Tente novamente.");
        });
    }
  }

  async function onSubmit(e) {
    setIsLoading(true);
    e?.preventDefault();
    // console.log(search);
    const aux = [];
    const tableInfo = [];

    try {
      for (let index = 0; index < search.length; index++) {
        const id = Number(search[index].id);
        const uf = search[index].uf;
        const dataini = moment(date.dataini, "DD/MM/YYYY").format("MM-DD-YYYY");
        const datafim = moment(date.datafim, "DD/MM/YYYY").format("MM-DD-YYYY");
        const { data } = await api("/ruralbusiness/cotacoes/listar", {
          params: { id, uf, periodo: "", dataini, datafim },
        });
        if (!data.error) {
          data[1].map((item) => {
            // item.data = moment(item.data).format("DD/MM/YYYY");
            item.nome = `${data[0].nome} - ${uf}`;
          });
          aux.push(...data[1]);
          tableInfo.push(data[0]);
        }
      }
      // console.log(tableInfo);
      setTable(tableInfo);
      setData(
        aux.sort((a, b) => {
          // Extracting date components from the date strings
          const dateA = a.data?.split("/").reverse().join("");
          const dateB = b.data?.split("/").reverse().join("");

          // Comparing dates
          if (dateA < dateB) {
            return -1;
          } else if (dateA > dateB) {
            return 1;
          } else {
            // Dates are equal, sort by name
            if (a.nome && b.nome) {
              return a.nome.localeCompare(b.nome);
            } else {
              return 0;
            }
          }
        })
      );
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const onFinish = async(values) => {
    setSearch(search.filter((el) => values.search.some((value) => value.id.toString() === el.id)));
    await onSubmit();
    setIframeLink(
      `<iframe id="rb-ct-embed" title="central de graficos" src="${
        process.env.REACT_APP_URL_CENTRAL_DE_GRAFICOS
      }?params=${encodeURIComponent(
        JSON.stringify(
          search.map((values) => ({
            id: values.id,
            uf: values.uf,
            dataini: moment(date?.dataini, "DD/MM/YYYY").format("MM-DD-YYYY"),
            datafim: moment(date?.datafim, "DD/MM/YYYY").format("MM-DD-YYYY"),
          }))
        )
      )}" allowtransparency="true" scrolling="no" style="width: 100%; height: 500px; border: none; overflow-y: hidden; overflow-x: hidden;" />`
    );
    message.success("Enviado com Sucesso!");
  };

  const onFinishFailed = () => {
    message.error("Falha no envio!");
  };

  const getOptions = useCallback((series) => {
    const opts = graphicCenterOptions(series);

    return {
      ...opts,
      chart: { ...opts.chart, style: { ...opts.chart?.style, height: 350 } },
      rangeSelector: { ...opts.rangeSelector, enabled: false },
      navigator: { ...opts.navigator, enabled: false },
      scrollbar: { ...opts.scrollbar, enabled: false },
    };
  }, []);

  return (
    <div style={{ padding: "1rem", display: "flex" }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ maxWidth: 550 }}
      >
        <Form.Item label="Período">
          <ConfigProvider locale={locale}>
            <RangePicker
              format={"DD/MM/YYYY"}
              onChange={(values, formatString) => {
                console.log(values);
                console.log(formatString);
                setDate({ dataini: formatString[0], datafim: formatString[1] });
              }}
            />
          </ConfigProvider>
        </Form.Item>
        <Form.List name="search">
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      name={[name, "id"]}
                      rules={[{ required: true, message: "Mercado" }]}
                      {...restField}
                    >
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        placeholder="Selecione uma Cotação"
                        filterOption={(input, option) =>
                          (option?.label.toLocaleLowerCase() ?? "").includes(
                            input.toLocaleLowerCase()
                          )
                        }
                        onChange={(value) => {
                          const order =
                            commList
                              .find((item) => item.id === value)
                              ?.ordem.toString() ?? "0";
                          setSearch((prevState) => {
                            const updatedState = [...prevState];
                            updatedState[index] = {
                              id: value.toString(),
                              order: order,
                              isLoading: prevState[index]?.isLoading ?? false,
                              uf: "",
                              ufList: prevState[index]?.ufList,
                            };
                            return updatedState;
                          });
                          getUfByOrder(value, index);
                        }}
                        options={optCommo}
                      />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "uf"]}>
                      <Select
                        allowClear
                        placeholder="UF"
                        options={search?.[index]?.ufList?.map((item) => ({
                          label: item.uf,
                          value: item.uf,
                        }))}
                      />
                    </Form.Item>
                    <FaMinusCircle onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                  >
                    Adicionar Campo
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            );
          }}
        </Form.List>
        <Button
          disabled={isLoading}
          type="primary"
          icon={<AiOutlineSearch />}
          htmlType="submit"
        >
          Buscar
        </Button>
      </Form>
      {data && data.length > 0 && table && table.length > 0 && (
        <div
          style={{
            width: "100%",
            maxWidth: "720px",
            marginInline: "auto",
          }}
        >
          <h2>Pré-Visualização</h2>
          <div
            style={{ marginTop: "1rem" }}
            dangerouslySetInnerHTML={{ __html: iframeLink }}
          />
          <div
            onClick={() => handleCopyText(iframeLink)}
            style={{
              height: "fit-content",
              position: "relative",
              cursor: "pointer",
              marginTop: "1rem",
            }}
          >
            <TextArea
              value={iframeLink}
              style={{
                height: "100px",
                paddingLeft: "3rem",
                cursor: "pointer",
              }}
            />
            <FaRegClipboard
              key={"copy"}
              title="Clique para copiar o endereço da imagem"
              style={{
                position: "absolute",
                zIndex: 10,
                left: "1rem",
                top: "2.5rem",
                width: "1.25rem",
                height: "1.25rem",
              }}
            />
          </div>
          {/* <div
              style={{
                backgroundColor: "#1c1c1c",
                borderRadius: "8px",
                width: "100%",
              }}
            >
              <StockChart data={data} options={(series) => getOptions(series)} />
              <TabelaCentralGraficos table={table} />
            </div> */}
        </div>
      )}
    </div>
  );
}
