import React, { useState, useEffect, useRef } from "react";
import { Button, Image, Table, Space, Input, Select } from "antd";
import { request } from "../../../functions/request";
import dayjs from "dayjs";
import { Content } from "antd/es/layout/layout";
import { useSearchParams } from "react-router-dom";
const { Search } = Input;

function formatData(dataArray) {
  let arrayData = [];
  let dataContent = Array.isArray(dataArray[0]) ? dataArray[0] : dataArray;

  if (
    typeof dataContent === "object" &&
    dataContent !== null &&
    !Array.isArray(dataContent)
  ) {
    const aux = [];
    for (const key in dataContent) {
      if (Array.isArray(dataContent[key]) && dataContent[key].length > 0) {
        dataContent[key][0].map((data) => {
          aux.push(data);
        });
      }
    }
    dataContent = aux;
  }

  if (!dataContent || dataContent === undefined) return dataContent;

  dataContent.map((data) => {
    arrayData.push({
      key: data.img_codigo,
      img_nome: data.img_nome,
      img_comentario: data.img_comentario,
      img_arquivo: data.img_arquivo,
      img_data: dayjs(data.img_data).format("DD/MM/YYYY HH:mm:ss"),
    });
  });
  return arrayData;
}

const editorialOpcoes = [
  { value: "fr01", label: "Interfrango" },
  { value: "bo01", label: "Interbois" },
  { value: "gr01", label: "Intergrãos" },
  { value: "su01", label: "Intersuíno" },
  { value: "le01", label: "Interleite" },
  { value: "per", label: "Personalidades" },
  { value: "gf01", label: "Gráficos" },
  { value: "cl01", label: "Clima" },
  { value: "etc", label: "Outros" },
];

const pesquisaOpcoes = [
  { value: "img_nome", label: "Legenda" },
  { value: "img_comentario", label: "Comentário" },
];

export default function BancoImagensPopUp() {
  const [imagensData, setImagensData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    filter: "",
    textSearchType: "img_comentario",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const tableRef = useRef(null);
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const pageSize =
    parseInt(URLSearchParams.get("pageSize")) ||
    parseInt(localStorage.getItem("pageSize")) ||
    10;

  const onSearch = async (value) => {
    let currentPathWithQuery = `/imagem/getImagemWithFilter?`;

    if (value === "" && search.filter === "") {
      currentPathWithQuery = `/imagem/getLastImagens`;
    } else {
      currentPathWithQuery += `img_nome=${
        search.textSearchType === "img_nome" ? value : ""
      }&img_comentario=${
        search.textSearchType === "img_comentario" ? value : ""
      }&img_arquivo=${search.filter}`;
    }

    setIsLoading(true);
    await request(currentPathWithQuery, {
      method: "GET",
    })
      .then((response) => {
        const data = formatData(response);
        setImagensData(data);
      })
      .finally(() => {
        scrollTop();
        setCurrentPage(1);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      title: "Imagem",
      dataIndex: "img_arquivo",
      render: (text) => (
        <Image
          style={{
            height: "100%",
            aspectRatio: "16 / 9",
            objectFit: "cover",
          }}
          preview={{ height: "50%" }}
          src={`${process.env.REACT_APP_BANCO_IMG_URL}${text}`}
        />
      ),
    },
    {
      title: "Nome",
      dataIndex: "img_nome",
    },
    {
      title: "Comentário",
      dataIndex: "img_comentario",
    },

    {
      title: "Data",
      dataIndex: "img_data",
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleChoose(record)}>
          Escolher
        </Button>
      ),
    },
  ];

  const handleChoose = (record) => {
    console.log("Escolheu:", record);
    if (window.opener) {
      var parentWindow = window.opener;
      parentWindow.onChooseImage(record);
    }
  };

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      const currentPathWithQuery = `/imagem/getLastImagens`;
      console.log(currentPathWithQuery);
      const response = await request(currentPathWithQuery, {
        method: "GET",
      });
      const data = formatData(response);
      console.log(data);
      setImagensData(data);
      setIsLoading(false);
    }
    getData();
  }, []);

  function scrollTop() {
    return window.scrollTo(0, 0);
  }

  return (
    <>
      <div ref={tableRef} style={{ width: "100%", padding: 10 }}>
        <Space direction="horizontal" size={"middle"}>
          <Space.Compact>
            <Select
              title="Filtro"
              placeholder={"Filtrar por editoria"}
              options={editorialOpcoes}
              allowClear
              onSelect={(value) =>
                setSearch((prevState) => ({
                  ...prevState,
                  filter: value,
                }))
              }
              onClear={() =>
                setSearch((prevState) => ({
                  ...prevState,
                  filter: "",
                }))
              }
            />
          </Space.Compact>

          <Space.Compact>
            <Search
              placeholder="Pesquisar..."
              onSearch={onSearch}
              addonBefore={
                <Select
                  defaultValue="img_comentario"
                  options={pesquisaOpcoes}
                  onSelect={(value) =>
                    setSearch((prevState) => ({
                      ...prevState,
                      textSearchType: value,
                    }))
                  }
                  onClear={() =>
                    setSearch((prevState) => ({
                      ...prevState,
                      textSearchType: "",
                    }))
                  }
                />
              }
            />
          </Space.Compact>
        </Space>
        <Content>
          <Table
            scroll={{ scrollToFirstRowOnChange: true }}
            columns={columns}
            dataSource={imagensData}
            tableLayout="auto"
            pagination={{
              position: ["topLeft", "bottomLeft"],
              pageSize: pageSize,
              current: parseInt(URLSearchParams.get("page")) || undefined,
              onChange: (page, pageSize) => {
                if (pageSize) {
                  localStorage.setItem("pageSize", pageSize.toString());
                }
                SetURLSearchParams({ pageSize: pageSize, page: page });
                scrollTop();
              },
            }}
            size="large"
            loading={isLoading}
          />
        </Content>
      </div>
    </>
  );
}
