import moment from "moment";
import debounce from "lodash/debounce";

const fetchData = (
  min,
  max,
  onSubmit
) => {
  const date = {
    dataini: moment(min).format("MM-DD-YYYY"),
    datafim: moment(max).format("MM-DD-YYYY"),
  };
  onSubmit(date);
};

const debouncedFetchData = debounce(
  (
    min,
    max,
    onSubmit
  ) => fetchData(min, max, onSubmit),
  1000,
);

export const graphicCenterOptions = (
  series,
  onSubmit,
) => ({
  title: {
    //   text: "Meu Gráfico Highstock",
  },
  legend: {
    enabled: true,
    align: "center",
    verticalAlign: "top",
    useHTML: true,
  },
  series: series,
  yAxis: {
    labels: {
      formatter: function () {
        return `${(Number.parseInt(this.value.toString()) > 0 ? " + " : "") + this.value}%`;
      },
    },
    plotLines: [{ value: 0, width: 2, color: "white" }],
  },
  plotOptions: { series: { compare: "percent" } },
  rangeSelector: {
    // selected: 3,
    dropdown: "responsive",
    buttonTheme: { style: { padding: "1rem" } },
    buttons: [
      {
        type: "month",
        count: 1,
        text: "1M",
        title: "Ver 1 mês",
      },
      {
        type: "month",
        count: 3,
        text: "3M",
        title: "Ver 3 meses",
      },
      {
        type: "month",
        count: 6,
        text: "6M",
        title: "Ver 6 meses",
      },
      {
        type: "year",
        count: 1,
        text: "1A",
        title: "Ver 1 ano",
      },
      {
        type: "year",
        count: 2,
        text: "2A",
        title: "Ver 2 anos",
      },
      {
        type: "all",
        text: "Tudo",
      },
    ],
  },
  chart: {
    animation: false,
    backgroundColor: "transparent",
    style: { height: 500 },
  },
  tooltip: {
    xDateFormat: "%A, %b, %d de %Y",
    stickOnContact: true,
    pointFormat:
      "<span>{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>",
    valueDecimals: 2,
    split: true,
  },
  xAxis: {
    events: {
      afterSetExtremes: (event) => {
        onSubmit && debouncedFetchData(event.min, event.max, onSubmit);
      },
    },
  },
});